import React from "react";

class Footer extends React.Component {
  render() {
    return (
<footer id="footer">
<div className="insideFooter">
                <ul className="nav">
                <li>
                  <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow">Cookie Policy</a>
                </li>
                  <li>
                    <a href="/uk/legal/contact-us/" target="_self">
                      Contact
                    </a>
                  </li>
                  <li>
                    <a href="/uk/legal/legal-notice/" target="_self">
                      Legal notice
                    </a>
                  </li>
                  <li>
                    <a href="/uk/legal/private-policy/" target="_self">
                      Privacy policy
                    </a>
                  </li>
                  <li>
                    <a href="/uk/legal/cookie-policy/" target="_self">
                      Cookie policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="/uk/legal/modern-slavery-act/MSA_JnJ_Limited_May_2024.pdf"
                      target="_blank"
                    >
                      Modern Slavery Act
                    </a>
                  </li>
                </ul>
                <div className="clear" />
                <p> </p>
                <p
                  style={{
                    float: "none"
                  }}
                >
                  <a
                    title="caring everyday offers coupons"
                    href="http://www.caringeveryday.co.uk/offers-coupons"
                    target="_blank"
                  >
                   
                  </a>
                </p><br />
                <p>
                  © Johnson & Johnson Limited 2021
                  <br />
                  This page is published by Johnson & Johnson, which is solely responsible for the content.
                  <br />
                  It is intended for a UK and Republic of Ireland audience.
                  <br />
                  Last updated: 31/08/2021
                </p>
                <div className="clear" />
              </div>
            </footer>
    );
  }
}

export default Footer;
